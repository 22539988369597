<template>
  <div>
    <GlPageWrap :title="reportTitle">
      <template
        v-if="isAdmin && featureAccess('COUNTERPARTY_CUSTOM')"
        #actions
      >
        <div class="m-fullwidth">
          <GlUploadFileButton
            class="fullwidth"
            @update="onFileChange"
          />
        </div>
      </template>
      <div class="counterparty-view">
        <div class="counterparty-view__head-wrapper">
          <div class="counterparty-view__head-sorting">
            <div
              class="flex align-center pointer mr-2"
              @click="$router.push('/counterparty')"
            >
              <gl-icon
                :height="30"
                name="back-arrow"
                style="min-width: 30px"
                :width="30"
              />
            </div>
            <GlButton
              class="height-38 m-flex-1"
              :disabled="!Object.keys(reportData).length"
              pale-light
              title="Conditions"
              @click="openConditions"
            >
              <template #beforeTitle>
                <img
                  alt="gear"
                  class="mr-2"
                  src="~@/assets/svg/tools/gear.svg"
                >
              </template>
            </GlButton>
            <SortBy
              class="m-flex-1"
              :disabled="
                !Object.keys(reportData).length || calculateStatus == 2
              "
              :disabled-items="disabledSortByItems"
              @sort-change="sortChanged"
            />
          </div>
          <div class="counterparty-view__head-select">
            <GlSelectButton
              :disabled="loading || calculateStatus == 2"
              :options="partBarOptions"
              :value="activePartBar"
              @input="changeActivePartBarValue"
            />
          </div>
          <div class="flex column align-end fs-12 m-align-start">
            <div class="mb-2">
              <span class="uppercase grey-text-6-e mr-1">From</span>
              <span class="bold-600">
                {{
                  dataTimestamp.$gte
                    ? formatDate(
                      new Date(dataTimestamp.$gte * 1000),
                      'dd.MM.yyyy HH:mm',
                    )
                    : '–'
                }}
              </span>
            </div>
            <div>
              <span class="uppercase grey-text-6-e mr-1">To</span>
              <span class="bold-600">
                {{
                  dataTimestamp.$lte
                    ? formatDate(
                      new Date(dataTimestamp.$lte * 1000),
                      'dd.MM.yyyy HH:mm',
                    )
                    : '–'
                }}
              </span>
            </div>
          </div>
        </div>

        <div
          v-if="calculateStatus == 2"
          class="counterparty-view__main-content-wrapper justify-center align-center"
        >
          <div class="mb-4">
            No data here yet
          </div>
        </div>
        <div
          v-else-if="
            calculateStatus == 0 ||
              (!loading && !Object.keys(reportData).length)
          "
          class="counterparty-view__main-content-wrapper justify-center align-center"
        >
          No data
        </div>
        <div
          v-else
          class="counterparty-view__main-content-wrapper"
        >
          <div
            class="counterparty-view__head-bars-wrapper flex fullwidth"
            :class="{
              'm-column-reverse': availableDirection !== 1,
              'flex-row-reverse m-column': availableDirection == 1,
            }"
          >
            <div
              v-if="availableDirection == 0 || availableDirection == 2"
              class=""
            >
              <div class="counterparty-view__head-incoming">
                <div class="bold-600">
                  Incoming
                </div>
                <VueSkeletonLoader
                  v-if="loading"
                  animation="wave"
                  :color="'#bec3d8'"
                  :height="20"
                  :radius="'2'"
                  type="rect"
                />
                <div
                  v-else
                  class="flex flex-wrap align-baseline gap-2"
                >
                  <div
                    v-if="accSofAmount"
                    class="bold"
                  >
                    {{ accSofAmount }}
                  </div>
                  <div
                    v-if="accSofPrice"
                    class="price fs-14"
                  >
                    {{ formatByPrice(accSofPrice) }}
                  </div>
                </div>
              </div>
            </div>
            <div
              class="counterparty-view__head-wallet text-right"
              :class="{
                'text-center': availableDirection == 2 && isDesktop,
              }"
            >
              <div v-if="reportData.transactions">
                {{ reportData.transactions }} transactions
              </div>
              <div v-if="reportData.activeWallets">
                {{ reportData.activeWallets }} wallets active during the chosen
                period
              </div>
            </div>
            <div
              v-if="
                availableDirection == 1 ||
                  (availableDirection == 2 && isDesktop)
              "
            >
              <div
                class="counterparty-view__head-outgoing"
                :class="{
                  'text-right': availableDirection == 2 && isDesktop,
                }"
              >
                <div class="bold-600">
                  Outgoing
                </div>
                <VueSkeletonLoader
                  v-if="loading"
                  animation="wave"
                  :color="'#bec3d8'"
                  :height="20"
                  :radius="'2'"
                  type="rect"
                />
                <div
                  v-else
                  class="flex flex-wrap align-baseline gap-2"
                >
                  <div
                    v-if="accUofAmount"
                    class="bold"
                  >
                    {{ accUofAmount }}
                  </div>
                  <div
                    v-if="accUofPrice"
                    class="price fs-14"
                  >
                    {{ formatByPrice(accUofPrice) }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="counterparty-view__bars-wrapper">
            <div
              v-if="availableDirection == 0 || availableDirection == 2"
              class="fullwidth mb-4"
            >
              <gl-scoring-bar-chart
                :active-part-bar="activePartBar"
                :basis-percent-graph-width="basisPercentGraphWidth"
                class="mb-4"
                :data-table="activeDataSourcesIncomingSorted"
                :full="showFullBarsIncoming"
                :loading="loading"
                :max-share="maxShareIncoming"
                :per-page="barsPerPage"
              />
              <div
                v-if="
                  activeDataSourcesIncomingSorted.length > barsPerPage &&
                    !loading &&
                    (!isDesktop || (isDesktop && availableDirection == 0))
                "
                class="flex fullwidth align-center justify-center"
              >
                <div
                  class="gl-button__more-less"
                  @click="toggleBarsPerPageIncoming"
                >
                  {{ showFullBarsIncoming ? 'Less' : 'More' }}
                </div>
              </div>
            </div>

            <div
              v-if="availableDirection == 1 || availableDirection == 2"
              class="fullwidth"
            >
              <div
                v-if="!isDesktop && availableDirection == 2"
                class="counterparty-view__head-bars-wrapper mt-4 m-column-reverse"
              >
                <div class="counterparty-view__head-outgoing">
                  <div class="bold-600">
                    Outgoing
                  </div>
                  <VueSkeletonLoader
                    v-if="loading"
                    animation="wave"
                    :color="'#bec3d8'"
                    :height="20"
                    :radius="'2'"
                    type="rect"
                  />
                  <div
                    v-else
                    class="flex flex-wrap align-baseline gap-2"
                  >
                    <div
                      v-if="accUofAmount"
                      class="bold"
                    >
                      {{ accUofAmount }}
                    </div>
                    <div
                      v-if="accUofPrice"
                      class="price fs-14"
                    >
                      {{ formatByPrice(accUofPrice) }}
                    </div>
                  </div>
                </div>

                <div
                  v-if="availableDirection == 1"
                  class="counterparty-view__head-wallet"
                >
                  <div v-if="reportData.transactions">
                    {{ reportData.transactions }} transactions
                  </div>
                  <div v-if="reportData.activeWallets">
                    {{ reportData.activeWallets }} wallets active during the
                    chosen period
                  </div>
                </div>
              </div>

              <gl-scoring-bar-chart
                :active-part-bar="activePartBar"
                :basis-percent-graph-width="basisPercentGraphWidth"
                class="mb-4"
                :data-table="activeDataSourcesOutgoingSorted"
                :full="showFullBarsOutgoing"
                :loading="loading"
                :max-share="maxShareOutgoing"
                :per-page="barsPerPage"
                reverse
              />
            </div>
          </div>
          <div
            v-if="
              ((Number(activeDataSourcesIncomingSorted.length) > barsPerPage &&
                isDesktop) ||
                Number(activeDataSourcesOutgoingSorted.length) > barsPerPage) &&
                availableDirection !== 0 &&
                !loading
            "
            class="flex fullwidth align-center justify-center"
          >
            <div
              class="gl-button__more-less"
              @click="toggleBarsPerPage"
            >
              {{
                (showFullBarsIncoming && isDesktop) || showFullBarsOutgoing
                  ? 'Less'
                  : 'More'
              }}
            </div>
          </div>
        </div>
      </div>
    </GlPageWrap>
  </div>
</template>

<script>
//Component
import GlPageWrap from '@/components/layout/gl-page-wrap'
import GlIcon from '@/components/gl-icon'
import GlButton from '@/components/gl-button'
import GlUploadFileButton from '@/components/gl-upload-file-button'
import GlSelectButton from '@/components/gl-select-button'
import GlScoringBarChart from '@/components/charts/gl-scoring-bar-chart.vue'
import VueSkeletonLoader from 'skeleton-loader-vue'
import GlLoader from '@/components/gl-loader'
import SortBy from '@/pages/counterparty/components/SortBy'
// utils
import { formatDate } from '@/utils/format-date'
import { formatByPrice } from '@/utils/format-by-price'
import { formatterAmountValue } from '@/utils/report-data-formatter'
import { toComaSeparate } from '@/utils/formatNumber'
import { formatter } from '@/utils/sourcesFormatter'
import { calculateRiskLevel } from '@/utils/risk-formatter'
import { featureAccess } from '@/utils/accesses'
import { sortingObjects, sourcesSortOrderArray } from '@/utils/sorting'
// Vuex
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
// Mixins
import deviceWidthMixin from '@/assets/mixins/deviceWidthMixin'
// libs
import html2canvas from 'html2canvas'

export default {
  name: 'ViewReport',
  components: {
    GlPageWrap,
    GlIcon,
    GlButton,
    GlSelectButton,
    GlScoringBarChart,
    VueSkeletonLoader,
    GlLoader,
    SortBy,
    GlUploadFileButton,
  },
  mixins: [deviceWidthMixin],
  data() {
    return {
      reportData: {},
      reportTitle: 'Counterparty report',
      loading: false,
      partBarOptions: [
        { name: 'Risk', value: 'riskLevel' },
        { name: 'Type', value: 'funds.type' },
        { name: 'Entity', value: 'owner' },
      ],
      activePartBar: { name: 'Type', value: 'funds.type' },
      showFullBarsIncoming: false,
      showFullBarsOutgoing: false,
      barsPerPage: 12,
      step: 10,
      availableDirection: 2,
      sortOrder: 'desc',
      sortKey: 'amount',
      disabledSortByItems: ['owner'],
      watchForReportHandler: null,
      selectedToken: {
        symbol: 'ETH',
        address: '',
      },
    }
  },
  computed: {
    ...mapState('analytics', ['coinData', 'currencyList']),
    ...mapGetters('user', ['isAdmin']),
    basisPercentGraphWidth() {
      let percent = 50
      if (this.isDesktop && this.availableDirection == 2) {
        percent = 40
        if (this.windowWidth < 1500) percent = 30
        if (this.windowWidth < 1300) percent = 25
      }
      if (this.isTablet && this.windowWidth < 900) {
        percent = 40
      }
      return percent
    },
    dataTimestamp() {
      return (
        (this.reportData &&
          this.reportData.tx_hash &&
          this.reportData.tx_hash.timestamp) ||
        {}
      )
    },
    calculateStatus() {
      return this.reportData && this.reportData.status
    },
    activeDataSourcesIncomingSorted() {
      if (!this.sortOrder || !this.sortKey) {
        return this.activeDataSourcesIncoming
      }
      return this.sortingObjects(
        this.activeDataSourcesIncoming,
        this.sortParams,
      )
    },
    activeDataSourcesOutgoingSorted() {
      if (!this.sortOrder || !this.sortKey) {
        return this.activeDataSourcesOutgoing
      }
      return this.sortingObjects(
        this.activeDataSourcesOutgoing,
        this.sortParams,
      )
    },
    activeDataSourcesIncoming() {
      return this.sortingObjects(
        this.formatSourcesAmount(
          formatter(this.sofResult, this.activePartBar.value),
        ),
        this.sortParams,
      )
    },
    activeDataSourcesOutgoing() {
      return this.sortingObjects(
        this.formatSourcesAmount(
          formatter(this.uofResult, this.activePartBar.value),
        ),
        this.sortParams,
      )
    },
    sofResult() {
      return (
        (this.reportData &&
          this.reportData.sources &&
          this.reportData.sources.sof &&
          this.formatSources(this.reportData.sources.sof)) ||
        []
      )
    },
    uofResult() {
      return (
        (this.reportData &&
          this.reportData.sources &&
          this.reportData.sources.uof &&
          this.formatSources(this.reportData.sources.uof)) ||
        []
      )
    },
    maxShareIncoming() {
      return this.getMaxShare(this.activeDataSourcesIncoming)
    },
    maxShareOutgoing() {
      return this.getMaxShare(this.activeDataSourcesOutgoing)
    },
    searchingParams() {
      return {
        selectedType: this.activePartBar.value,
        sortBy: '',
      }
    },
    accSofPrice() {
      return this.accumulateValues(this.activeDataSourcesIncoming, 'price')
    },
    accUofPrice() {
      return this.accumulateValues(this.activeDataSourcesOutgoing, 'price')
    },
    accSofAmount() {
      return this.accumulateValues(
        this.activeDataSourcesIncoming,
        'amount',
        this.formatterAmountValue,
      )
    },
    accUofAmount() {
      return this.accumulateValues(
        this.activeDataSourcesOutgoing,
        'amount',
        this.formatterAmountValue,
      )
    },
    sortParams() {
      return [
        { key: this.sortKey, order: this.sortOrder },
        ...sourcesSortOrderArray,
      ]
    },
  },
  watch: {
    isDesktop: {
      handler() {
        this.showFullBarsIncoming = false
        this.showFullBarsOutgoing = false
      },
    },
  },
  async mounted() {
    const { availableDirection } = this.$route.query
    this.availableDirection = availableDirection

    const { query } = this.$route

    this.setCoin(query.type)
    this.selectedToken.symbol = this.coinData.label
    this.selectedToken.address = this.$route.query.token || ''
    if (query.token) {
      await this.getToken(query.token)
    }

    this.loadReport()
    this.gettingReportInterval()
  },
  beforeDestroy() {
    clearTimeout(this.watchForReportHandler)
  },
  beforeRouteLeave(from, to, next) {
    clearTimeout(this.watchForReportHandler)
    next()
  },
  methods: {
    ...mapActions('counterparty', [
      'editCounterpartyReport',
      'getCounterpartyReportById',
    ]),
    ...mapActions({
      getCurrencyInfo: 'analytics/getCurrencyInfo',
    }),
    ...mapMutations({
      SET_COIN_TYPE: 'analytics/SET_COIN_TYPE',
      SET_COIN_DATA: 'analytics/SET_COIN_DATA',
    }),
    ...mapActions('entity', ['getEntityList']),
    formatter,
    formatDate,
    formatByPrice,
    formatterAmountValue,
    calculateRiskLevel,
    featureAccess,
    sortingObjects,
    toComaSeparate,
    async getToken(address) {
      try {
        const currencies = await this.getCurrencyInfo({ address })
        const token = currencies[address]

        if (token) {
          this.selectedToken.address = token.address
          this.selectedToken.decimals = token.decimals ?? 1
          this.selectedToken.symbol = token.currency ?? ''
        }
      } catch (error) {
        console.log(error)
      }
    },
    gettingReportInterval(interval = 36000) {
      this.watchForReportHandler = setTimeout(() => {
        this.loadReport(false).finally(() => {
          if (
            this.$route.name === 'view-counterparty-report' &&
            this.calculateStatus !== 1
          ) {
            this.gettingReportInterval(interval)
          }
        })
      }, interval)
    },
    openConditions() {
      const {
        title,
        address,
        tx_hash,
        direction,
        token,
        maxDepth,
        thresholdAmount,
      } = this.reportData

      let address_or_cluster = null

      if (typeof address === 'object' && address.cluster) {
        address_or_cluster = Array.isArray(address.cluster)
          ? address.cluster.join(', ')
          : address.cluster
      }
      if (Array.isArray(address)) {
        address_or_cluster = address.join(', ')
      }
      if (typeof address === 'string') {
        address_or_cluster = address
      }

      this.$emit('edit-report-modal-open', {
        title,
        address,
        tx_hash,
        direction,
        token,
        maxDepth,
        thresholdAmount,
        address_or_cluster,
      })
    },
    toggleBarsPerPage() {
      if (this.isDesktop && this.availableDirection == 2) {
        this.showFullBarsIncoming = !this.showFullBarsIncoming
        this.showFullBarsOutgoing = !this.showFullBarsOutgoing
      } else {
        this.showFullBarsOutgoing = !this.showFullBarsOutgoing
      }
    },
    toggleBarsPerPageIncoming() {
      this.showFullBarsIncoming = !this.showFullBarsIncoming
    },
    async loadReport(isNeedLoading = true) {
      if (isNeedLoading) {
        this.loading = true
      }

      await this.getCounterpartyReportById({
        id: this.$route.params.reportId,
        searchingParams: this.searchingParams,
      })
        .then(data => {
          this.setReportData(data)
          this.setEntityToReportData(data)

          this.$router
            .replace({
              query: {
                ...this.$route.query,
                availableDirection: this.availableDirection,
              },
            })
            .catch(err => err)

        })
        .catch(e => {
          this.$toasted.global.error({
            message: `${e?.response?.data?.message || e}`,
          })
        })
        .finally(() => {
          this.loading = false
          if (this.calculateStatus === 1 && this.isDesktop) {
            // this.setScreenshot()
          }
        })
    },
    async setReportData(data) {
      if (!data) return
      this.reportData = data

      this.reportData = {
        ...data,
        direction: data.direction ?? data?.direction ?? data?.directions,
        sources: this.separateSources(data.sources),
      }

      if (data.title) {
        this.reportTitle = data.title
      }

      this.availableDirection =
        this.reportData?.direction ?? this.reportData?.direction ?? 2

      const curr = data.sources?.find(s => s.currency)
      if (curr?.currency) {
        await this.getToken(curr.currency)
      }

      if (this.reportData.status == 1) {
        clearTimeout(this.watchForReportHandler)
      }
    },
    async setEntityToReportData(sourceData) {
      if (sourceData.sources && sourceData.sources.length) {
        this.getEntityList({
          names: [...new Set(sourceData.sources.map(el => el.owner))],
        }).then(data => {
          sourceData.sources = sourceData.sources?.map(source => {
            const entityId =
              data?.items?.find(
                el =>
                  el?.name?.value?.toLowerCase() ===
                  source.owner?.toLowerCase(),
              )?.id || null
            return { ...source, entityId }
          })

          this.setReportData(sourceData)
        })
      }
    },
    async customLoadReport(data) {
      this.loading = false
      clearTimeout(this.watchForReportHandler)
      if (data) {
        this.reportData = data

        this.reportData = {
          ...data,
          direction: data.direction ?? data?.direction ?? data?.directions,
          sources: this.separateSources(data.sources),
        }

        if (data.title) {
          this.reportTitle = data.title
        }

        this.availableDirection =
          this.reportData?.direction ?? this.reportData?.direction ?? 2

        const curr = data.sources?.find(s => s.currency)
        if (curr?.currency) {
          await this.getToken(curr.currency)
        }
      }

      this.$router
        .replace({
          query: {
            ...this.$route.query,
            availableDirection: this.availableDirection,
          },
        })
        .catch(err => err)
    },
    async onFileChange(e) {
      const file = e.target.files[0]
      const data = await new Response(file).json()
      this.customLoadReport(data)
    },
    setCoin(coin) {
      if (!coin) return
      const localCoinData =
        this.currencyList.find(item => item.key === coin.toLowerCase()) ||
        this.currencyList[0]

      this.SET_COIN_TYPE(localCoinData.key)
      this.SET_COIN_DATA(localCoinData)
    },
    formatSources(sources) {
      const totalAmount = sources.reduce(
        (sum, source) => sum + source.amount,
        0,
      )
      return (
        sources &&
        sources.map(source => {
          return {
            ...source,
            share: source.amount / totalAmount,
            riskLevel: this.calculateRiskLevel(
              source.funds.default ? -1 : source.funds.score,
            ),
          }
        })
      )
    },
    formatSourcesAmount(sources) {
      return (
        sources &&
        sources.map(source => {
          return {
            ...source,
            formattedAmount: this.toComaSeparate(
              this.formatterAmountValue(
                source.amount,
                this.selectedToken.decimals ?? this.coinData.decimals,
                this.selectedToken.symbol ?? this.coinData.label,
              ),
            ),
          }
        })
      )
    },
    separateSources(sources) {
      const sof = []
      const uof = []
      sources &&
        sources.forEach(source => {
          if (source.directionUp) {
            uof.push(source)
          } else {
            sof.push(source)
          }
        })
      return { sof, uof }
    },
    changeActivePartBarValue({ name, value }) {
      this.activePartBar = { name, value }
      if (value == 'riskLevel') {
        this.disabledSortByItems = ['owner', 'funds.name']
      } else if (value == 'owner') {
        this.disabledSortByItems = ['funds.name']
      } else if (value == 'funds.type') {
        this.disabledSortByItems = ['owner']
      } else if (this.disabledSortByItems.length) {
        this.disabledSortByItems = []
      }
    },
    sortChanged({ value, sortOrder }) {
      sortOrder ? (this.sortKey = value) : (this.sortKey = '')
      this.sortOrder = sortOrder
    },
    accumulateValues(dataSource, key, formatterValue = null) {
      const hasInvalidValue = dataSource.some(el => !el[key])
      const totalSum = dataSource.reduce((acc, el) => acc + (el[key] || 0), 0)
      if (hasInvalidValue) {
        return 0
      }
      return formatterValue
        ? this.toComaSeparate(formatterValue(
            totalSum,
            this.selectedToken.decimals ?? this.coinData.decimals,
            this.selectedToken.symbol ?? this.coinData.label,
          ))
        : totalSum
    },
    getMaxShare(dataSource) {
      if (dataSource && dataSource.length) {
        const maxValue = dataSource.reduce(
          (max, obj) => (obj.share > max ? obj.share : max),
          dataSource[0].share,
        )
        return Math.ceil((maxValue * 100) / this.step) * this.step
      } else {
        return 100
      }
    },
    setScreenshot() {
      this.$nextTick(() => {
        setTimeout(() => {
          const element = document.querySelector(
            '.counterparty-view__bars-wrapper',
          )
          html2canvas(element).then(canvas => {
            const img = canvas.toDataURL('image/png')
            console.log(img)
          })
        }, 3000)
      })
    },
  },
}
</script>

<style>
.app-root:has(.counterparty-view) {
  display: unset;
}
.counterparty-view {
  display: flex;
  flex-direction: column;
  background: var(--white);
  min-height: var(--page-content-height);
  max-height: var(--page-content-height);
}

.counterparty-view__head-wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 24px;
  margin: 24px;
  margin-bottom: 0;
  padding-bottom: 24px;
  border-bottom: 1px solid var(--dark-grey-d-3);
}

.counterparty-view__head-wrapper > div:nth-child(2) {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-bottom: 24px;
}

.counterparty-view__head-sorting {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.counterparty-view__head-sorting .gl-button {
  text-transform: unset;
  font-weight: 500;
  font-size: 16px;
}

.counterparty-view__main-content-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px;
  overflow: auto;
  flex: 1;
}

.counterparty-view__head-bars-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 16px;
}

.counterparty-view__head-incoming,
.counterparty-view__head-outgoing {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;
}

.counterparty-view__head-wallet {
  display: flex;
  flex-direction: column;
  font-weight: 500;
}

.counterparty-view__bars-wrapper {
  display: flex;
  width: 100%;
  gap: 2px;
}

@media (max-width: 1350px) {
  .counterparty-view__head-wrapper > div:nth-child(2) {
    position: static;
    top: unset;
    left: unset;
    transform: unset;
    padding-bottom: unset;
  }
}

@media (max-width: 1200px) {
  .counterparty-view__bars-wrapper {
    flex-wrap: wrap;
  }
  .counterparty-view__head-select {
    /* display: flex;
    justify-content: center;
    width: 100%;
    order: 3; */
  }
}

@media (max-width: 1000px) {
  .counterparty-view__head-select {
    display: flex;
    justify-content: center;
    width: 100%;
    order: 3;
  }
}

@media (max-width: 767px) {
  .counterparty-view {
    min-height: unset;
    max-height: initial;
  }

  .counterparty-view__head-wallet {
    text-align: center;
  }

  .counterparty-view__head-wrapper {
    margin: 12px;
    gap: 16px;
  }

  .counterparty-view__main-content-wrapper {
    padding: 12px;
  }
}

@media (max-width: 1024px) and (max-height: 650px) {
  .counterparty-view {
    min-height: unset;
    max-height: initial;
  }
}
</style>
