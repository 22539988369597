<template>
  <div>
    <GlButton
      class="height-38 fullwidth"
      dark
      :title="title"
      @click="openFileInput"
    />
    <input
      ref="inputUploader"
      accept=".json"
      class="display-none"
      type="file"
      @input="onFileChange"
    >
  </div>
</template>

<script>
//Components
import GlButton from '@/components/gl-button'

export default {
  name: 'GlUploadFileButton',
  components: {
    GlButton,
  },
  props: {
    title: {
      type: String,
      default: 'Upload JSON data',
    },
  },
  methods: {
    openFileInput() {
      this.$refs.inputUploader.click()
    },
    onFileChange(event) {
      this.$emit('update', event)
    },
  },
}
</script>
