<template>
  <div class="sort-by__wrapper">
    <GlButton
      class="height-38 m-fullwidth"
      :disabled="disabled"
      pale-dark
      title="Sort by"
      @click="sortOpenClose"
    >
      <template #beforeTitle>
        <img
          alt="sort-by"
          class="mr-2"
          src="~@/assets/svg/tools/sort-by.svg"
        >
      </template>
    </GlButton>

    <transition name="fade">
      <div
        v-if="isOpen"
        v-on-clickaway="outsideClick"
        class="sort-by__dropdown"
      >
        <div
          v-for="item in optionsForSort"
          :key="item.value"
          class="fullwidth"
        >
          <div
            v-if="!item.disabled"
            class="sort-by__dropdown-item"
            :class="{
              'sort-by__dropdown-item--active': item.sortOrder,
              'sort-by__dropdown-item--disabled': item.disabled,
            }"
            @click="sortChange(item)"
          >
            <div class="sort-by__dropdown-item-text">
              {{ item.label }}
            </div>
            <div
              class="sort-by__dropdown-item-action"
              :class="{
                'sort-by__dropdown-item-action-up': item.sortOrder === 'asc',
              }"
            >
              <gl-icon
                :height="21"
                name="sort"
                :width="21"
              />
            </div>
          </div>
        </div>
      </div>
    </transition>
    <div
      v-if="isSortByActive"
      class="sort-by__active"
    />
  </div>
</template>

<script>
// Libs
import { mixin as clickaway } from 'vue-clickaway'
// Components
import GlButton from '@/components/gl-button'
import GlIcon from '@/components/gl-icon'

export default {
  name: 'SortBy',
  components: { GlButton, GlIcon },
  mixins: [clickaway],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    disabledItems: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      optionsForSort: [
        {
          label: 'Amount',
          value: 'amount',
          sortOrder: '',
          disabled: false,
        },
        {
          label: 'Score',
          value: 'funds.score',
          sortOrder: '',
          disabled: false,
        },
        {
          label: 'Owner',
          value: 'owner',
          sortOrder: '',
          disabled: false,
        },
        {
          label: 'Type',
          value: 'funds.name',
          sortOrder: '',
          disabled: false,
        },
      ],
      isOpen: false,
    }
  },
  computed: {
    isSortByActive() {
      return this.optionsForSort.some(e => e.sortOrder)
    },
  },
  watch: {
    disabledItems: {
      handler(newDisabledItems) {
        this.optionsForSort = this.optionsForSort.map(e => {
          if (newDisabledItems.includes(e.value)) {
            if (e.sortOrder) {
              this.$emit('sort-change', { value: 'amount', sortOrder: '' })
            }
            
            return {
              ...e,
              sortOrder: '',
              disabled: true,
            }
          } else return { ...e, disabled: false }
        })
      },
      immediate: true,
    },
  },
  methods: {
    sortOpenClose() {
      this.isOpen = !this.isOpen
    },
    outsideClick() {
      this.isOpen = false
    },
    sortChange(item) {
      if (item.disabled) return
      this.cycleSortOrder(item)
      this.optionsForSort = this.optionsForSort.map(el => {
        if (el.value !== item.value) {
          return { ...el, sortOrder: '' }
        } else return { ...el }
      })
      this.$emit('sort-change', item)
    },
    cycleSortOrder(item) {
      if (item.sortOrder === '') {
        item.sortOrder = 'asc'
      } else if (item.sortOrder === 'asc') {
        item.sortOrder = 'desc'
      } else {
        item.sortOrder = ''
      }
    },
  },
}
</script>

<style scoped>
.sort-by__wrapper {
  position: relative;
}

.sort-by__active {
  position: absolute;
  top: 4px;
  right: 4px;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background: var(--lipstick);
}

.sort-by__dropdown {
  display: flex;
  flex-direction: column;
  align-items: start;
  background-color: var(--white);
  border-radius: 8px;
  box-shadow: 0px 0px 16px 0px rgba(9, 21, 64, 0.1);
  position: absolute;
  left: 50%;
  top: calc(100% + 8px);
  transform: translate(-50%, 0);
  z-index: 1;
}
.sort-by__dropdown-item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  user-select: none;
  padding: 8px 16px;
  cursor: pointer;
}

.sort-by__dropdown-item-text {
  font-size: 16px;
  line-height: 1;
  white-space: nowrap;
}

.sort-by__dropdown-item--active {
  background: var(--pale-blue-transparent);
  border-radius: 8px;
}

.sort-by__dropdown-item-action {
  padding: 0 4px;
  padding-top: 2px;
  border-radius: 4px;
}

.sort-by__dropdown-item--active .sort-by__dropdown-item-action {
  background: var(--accent-reflex-blue);
}
.sort-by__dropdown-item--active .sort-by__dropdown-item-text {
  font-weight: 500;
}
.sort-by__dropdown-item--active .gl-icon {
  fill: var(--white) !important;
}
.sort-by__dropdown-item--active .sort-by__dropdown-item-action-up {
  transform: rotate3d(1, 0, 0, 180deg);
}

.sort-by__dropdown-item--disabled {
  opacity: 0.6;
  cursor: not-allowed;
}
</style>
